import { Option, MailRecipientCfg, Drone } from "@ddr/models";

export type ShopName = "0a24d1-f7" | "dronedeerrecovery";

export type ShopConfig = {
  DEER_PRODUCT: number;
  displayName: string;
  publicStorefront: string;
  shopId: number;
  /** Admin email addresses */
  adminNotify: MailRecipientCfg[];
  allowedUsers: string[];
  /** Staff who can approve/reject reviews, pilots, complaints, etc */
  staff: string[];
};

export type Shops = Record<ShopName, ShopConfig>;

export const SHOPS: Shops = {
  dronedeerrecovery: {
    DEER_PRODUCT: 8035517169864,
    // PET_PRODUCT: 2222,
    // AG_PRODUCT: 3333,
    displayName: "DDR",
    publicStorefront: "https://dronedeerrecovery.com",
    shopId: 62891589832,
    adminNotify: [{ email: "subscriptions@dronedeerrecovery.com" }],
    allowedUsers: ["*"],
    staff: ["@dronedeerrecovery.com", "keimdigital@gmail.com"],
  },
  "0a24d1-f7": {
    DEER_PRODUCT: 7249598218327,
    // PET_PRODUCT: 2222,
    // AG_PRODUCT: 3333,
    displayName: "Keim Digital",
    publicStorefront: `https://0a24d1-f7.myshopify.com`,
    shopId: 59435286615,
    adminNotify: [{ email: "kkeim77@gmail.com" }],
    allowedUsers: ["*"],
    staff: ["keimdigital@gmail.com"],
  },
};

export const MAPS_API_KEY = "AIzaSyDkgNfhfmbkratNhG06vS6lEkm1uyfRp-8";
/** Styled map for public pilot map */
export const PUBLIC_MAP_STYLED_ID = "b764d39b5288f1dc";

export const SHOPIFY_API_VERSION = "2024-07";

/** Pilots can connect with up to {MAX} other pilots in {PERIOD_HRS} hours */
export const CONNECTIONS = {
  MAX: 7,
  PERIOD_HRS: 48,
  REMAINING_WARNING: 2,
  /** Length of time to show contact info after contacting pilot */
  SHOW_CONTACT_INFO_PERIOD_HRS: 168, // 7 days
};

export const RECENT_REVIEWS_LIMIT = 5;

/**
 * Map file extension to mime type\
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types\
 *
 * We only want to support optimized formats
 */
export const MIME_TYPES = new Map([
  ["jpg", "image/jpeg"],
  ["jpeg", "image/jpeg"],
  ["png", "image/png"],
  ["webp", "image/webp"],
  ["heic", "image/heic"],
]);

export const CHANGE_REQ_FORM =
  "https://share.hsforms.com/1SYHzB_QJTVO1BXhJOcX_awr5qba";

export const COMPLAINT_DISPUTE_FORM =
  "https://share.hsforms.com/12ubnLl-NT7u-A9Cn47sELgr5qba";

export const UPLOAD_TEMP_LICENSE =
  "https://share.hsforms.com/1dp4HjbsfSD2yD7vbURNtlQr5qba";

export const MAX_PHOTO_SIZE = {
  display: "20 MB",
  bytes: 20000000,
};

export const MAX_REVIEW_PHOTOS = 3;

export const EVENTS = {
  followup: {
    action: "check-hired",
    /** Delays in hours */
    delays: [0.5, 1, 3, 24],
  },
} as const satisfies Record<string, { action: string; delays?: number[] }>;

// Must be a good bit longer than the last hiring followup message
export const LEAD_LOOKBACK_PERIOD_HRS = 168; // 7 days

/** Service address radius for public pin */
export const SVC_RADIUS_MAX = {
  display: "3 miles",
  miles: 3,
};

/** Offset for home pin */
export const HOME_OFFSET = 0.01;
/** Used as a fake marker to prevent overly zoomed in map for one result */
export const ONE_RESULT_OFFSET = 0.03;

export const DRONES: Drone[] = [
  {
    name: "DJI Matrice 30T",
    image:
      "https://cdn.shopify.com/s/files/1/0628/9158/9832/files/65a6802f4a41a5412ced3584_Matrice-30-T__01379.webp?v=1707911341",
    description: `The Matrice 30T sports a best-in-class integrated thermal camera w/ 200x zoom. It can fly in rain and snow; gusting winds up to 50mph. When combined with a spotlight, it is very capable for night-time recoveries.`,
  },
  {
    name: "DJI Matrice 350T RTK",
    image:
      "https://cdn.shopify.com/s/files/1/0628/9158/9832/files/DJI_Matrice_350T_RTK_Cropped.png?v=1725146673",
    description: `The Matrice 350T RTK sports a best-in-class integrated thermal camera w/ 200x zoom. It can fly in rain and snow; gusting winds up to 50mph. When combined with a spotlight, it is very capable for night-time recoveries.`,
  },
];

export const QUALITY_GUAGE =
  "https://cdn.shopify.com/s/files/1/0628/9158/9832/files/Full_Quality_Drone.png?v=1707911677";

export const EQUIPMENT: Option[] = [
  { name: '24" Battery Powered Monitor' },
  { name: "Other monitor" },
];

// Note: update social icons in apps/react/src/components/socials.tsx if updating this
export const SOCIALS: Option[] = [
  { name: "Instagram" },
  { name: "Facebook" },
  { name: "TikTok" },
  { name: "YouTube" },
  { name: "Other" },
];

export const TWILIO_FROM = "+18447469154";

export const LINKS = {
  guarantee: {
    href: "https://www.dronedeerrecovery.com/pages/drone-deer-guarantee",
    text: "The Drone Deer Recovery Guarantee",
  },
};

export const PART_107_LICENSE_REGEX = /^[0-9]{4,}$/;

type LinkCfg =
  | {
      href: string;
      text: string;
    }
  | string;

export class Shop {
  private readonly shop: ShopConfig;
  public readonly LINKS;

  constructor(shopName: ShopName) {
    this.shop = SHOPS[shopName];
    const sf = this.shop.publicStorefront;

    this.LINKS = {
      SUB_COLLECTION: `${sf}/collections/subscriptions`,
      CONTACT: `${sf}/pages/contact`,
    } satisfies Record<string, LinkCfg>;
  }
}
